import PropTypes from "prop-types";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@material-ui/core/styles";
import { Icon } from "@iconify/react";
import logOutOutline from "@iconify/icons-eva/log-out-outline";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
} from "@material-ui/core";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import { MHidden } from "../../components/@material-extend";
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import ContactsPopover from "./ContactsPopover";
import NotificationsPopover from "./NotificationsPopover";
import ds from "src/dataService/apiRoutes";
import { setSession } from "src/utils/jwt";
import jwtDecode from "jwt-decode";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import useAuth from "src/hooks/useAuth";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [login, setlogin] = useState(true);
  const { isCollapse } = useCollapseDrawer();
  const { setIsAuthenticated } = useAuth();
  const logout = async () => {
    const idToken = localStorage.getItem("idToken");
    const { email } = jwtDecode(idToken);
    const { data } = await ds.signOut(email);
    setSession(null, null, null);
    setIsAuthenticated(false);
    if (!data.isLogIN) {
      setlogin(false);
    }
  };
  return login ? (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <NotificationsPopover />
          <Button
            variant="contained"
            startIcon={<Icon icon={logOutOutline} />}
            sx={{ width: "132px" }}
            onClick={logout}
          >
            Sign Out
          </Button>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  ) : (
    <Navigate to={"/auth/login"} replace />
  );
}
