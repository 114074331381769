import { createContext, useState } from "react";
import PropTypes from "prop-types";
// utils
import {
  validateAccessToken,
  setSession,
  checkExpiryTime,
  newSession,
  validateIdToken,
} from "../utils/jwt";
import ds from "src/dataService/apiRoutes";
import jwtDecode from "jwt-decode";

// ----------------------------------------------------------------------

const AuthContext = createContext({});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const login = async (email, password) => {
    const { data } = await ds.logIn(email, password);
    const userName = { name: data.result.data.idToken.payload.name };
    setUser(data.result ? userName : null);
    const accessToken = data.result
      ? data.result.data.accessToken.jwtToken
      : null;
    const refreshToken = data.result
      ? data.result.data.refreshToken.token
      : null;
    const idToken = data.result ? data.result.data.idToken.jwtToken : null;
    setSession(accessToken, refreshToken, idToken);
    setIsAuthenticated(true);
  };

  const register = async (firstName, lastName, email, password) => {
    const { data } = await ds.signUp(firstName, lastName, email, password);
    return data;
  };

  const manageIsAuthenticated = async (accessToken) => {
    const { data } = await validateAccessToken(accessToken);
    if (!data.result.isTokenValid) {
      setSession(null, null, null);
      setUser(null);
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
    return;
  };

  const manageIdToken = async (idToken) => {
    const { data } = await validateIdToken(idToken);
    if (!data.result.isTokenValid) {
      setSession(null, null, null);
      setUser(null);
      setIsAuthenticated(false);
    } else {
      const data = jwtDecode(idToken);
      setUser({ name: data.name });
    }
    return;
  };

  const manageNewSession = async (email, refreshToken) => {
    await newSession(email, refreshToken);
    return;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        manageIsAuthenticated,
        user,
        method: "jwt",
        validateAccessToken,
        login,
        register,
        checkExpiryTime,
        manageNewSession,
        manageIdToken,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
