import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
//
import ds from "src/dataService/apiRoutes";

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const validateAccessToken = async (accessToken) => {
  if (!accessToken) {
    return false;
  } else {
    return await ds.validateCognitoToken("access", accessToken);
  }
};

const validateIdToken = async (idToken) => {
  if (!idToken) {
    return false;
  } else {
    return await ds.validateCognitoToken("id", idToken);
  }
};

const newSession = async (email, refreshToken) => {
  const session = await ds.generateNewSession(email, refreshToken);
  if (session) {
    const accessToken = session.result
      ? session.result.data.accessToken.jwtToken
      : null;
    const refreshToken = session.result
      ? session.result.data.refreshToken.token
      : null;
    const idToken = session.result
      ? session.result.data.idToken.jwtToken
      : null;
    setSession(accessToken, refreshToken, idToken);
  }
  setSession(null, null, null);
  return;
};

const checkExpiryTime = (accessToken) => {
  const { exp } = jwtDecode(accessToken);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  if (timeLeft < 10) {
    return true;
  } else {
    return false;
  }
};

const setSession = (accessToken, refreshToken, idToken) => {
  if (accessToken && refreshToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("idToken", idToken);
    ds.axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("idToken");
    delete ds.axios.defaults.headers.Authorization;
  }
};

export {
  isValidToken,
  validateAccessToken,
  setSession,
  verify,
  sign,
  checkExpiryTime,
  newSession,
  validateIdToken,
};
