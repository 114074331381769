import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// hooks
import useAuth from "../hooks/useAuth";
// pages
import Login from "../pages/authentication/Login";
import jwtDecode from "jwt-decode";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const {
    isAuthenticated,
    manageIsAuthenticated,
    checkExpiryTime,
    manageNewSession,
    manageIdToken,
  } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const accessToken = localStorage.getItem("accessToken");
  const idToken = localStorage.getItem("idToken");

  if (accessToken) {
    const isTokenExpired = checkExpiryTime(accessToken);
    if (isTokenExpired) {
      if (idToken) {
        manageIdToken(idToken);
        const data = jwtDecode(idToken);
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          manageNewSession(data.email, refreshToken);
        }
      }
    }
  }

  if (accessToken && !isAuthenticated) {
    manageIsAuthenticated(accessToken);
    if (idToken) {
      manageIdToken(idToken);
    }
  }

  if (!accessToken && !isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
