// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  kanban: getIcon("ic_kanban"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: "general",
  //   items: [
  //     {
  //       title: "app",
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard,
  //     },
  //     {
  //       title: "e-commerce",
  //       path: PATH_DASHBOARD.general.ecommerce,
  //       icon: ICONS.ecommerce,
  //     },
  //     {
  //       title: "analytics",
  //       path: PATH_DASHBOARD.general.analytics,
  //       icon: ICONS.analytics,
  //     },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "admin",
    items: [
      // MANAGEMENT : ADMIN
      {
        title: "admin",
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.user,
        children: [
          { title: "list", path: PATH_DASHBOARD.admin.list },
          { title: "create", path: PATH_DASHBOARD.admin.newAdmin },
        ],
      },
    ],
  },
  {
    subheader: "user",
    items: [
      // MANAGEMENT : USER
      {
        title: "user",
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [{ title: "list", path: PATH_DASHBOARD.user.list }],
      },
    ],
  },
  {
    subheader: "influencers",
    items: [
      // MANAGEMENT : USER
      {
        title: "influencers",
        path: PATH_DASHBOARD.influencer.root,
        icon: ICONS.user,
        children: [{ title: "list", path: PATH_DASHBOARD.influencer.list }],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: "app",
  //   items: [
  //     {
  //       title: "mail",
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>,
  //     },
  //     { title: "chat", path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     {
  //       title: "calendar",
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: "kanban",
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
  //
];

export default sidebarConfig;
