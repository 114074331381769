import axiosMain from "axios";

const BASE_URL = process.env.REACT_APP_ADMIN_API_URL;
//User URL
const USER_URL = `/users`;
const ADMIN_URL = `/admins`;
const INFLUENCER_URL = `/influencer`;

//Create Instance of Axios with default settings
const axios = axiosMain.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

//Create Instance of Axios for File insertion with default settings
const fileAxios = axiosMain.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    accept: "*/*",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status == 403) {
      return Promise.reject(error);
    }
    if (401 === error?.response?.status) {
      localStorage.removeItem("accessToken");
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return config;
});

//Api calls
//.........Users Api
const findUserList = (page, rowsPerPage, fieldName, fieldValue) => {
  return axios.get(
    `${USER_URL}?page=${page}&limit=${rowsPerPage}${
      fieldName.length !== 0 && fieldValue.length !== 0
        ? `&${fieldName}=${fieldValue}`
        : ""
    }`
  );
};

//.........Admins Api

const logIn = (email, password) => {
  return axios.post(`${ADMIN_URL}/login`, { email, password });
};
const getAdminList = () => {
  return axios.get(`${ADMIN_URL}`, {});
};

const getInfluencerList = (page, rowsPerPage) => {
  return axios.get(`${INFLUENCER_URL}?page=${page}&limit=${rowsPerPage}`);
};
const signUp = (firstName, lastName, email, password) => {
  return axios.post(`${ADMIN_URL}/signup`, {
    firstName,
    lastName,
    email,
    password,
  });
};

const signOut = (email) => {
  return axios.post(`${ADMIN_URL}/signout`, {
    email,
  });
};

const validateCognitoToken = (type, accessToken) => {
  return axios.post(`${ADMIN_URL}/verifyAccessToken`, {
    type,
    accessToken,
  });
};

const generateNewSession = (email, refreshToken) => {
  return axios.post(`${ADMIN_URL}/refreshSession`, {
    email,
    refreshToken,
  });
};

export default {
  //Api calls
  findUserList,
  signUp,
  logIn,
  signOut,
  validateCognitoToken,
  generateNewSession,
  getAdminList,
  getInfluencerList,
  //axios
  axios,
};
