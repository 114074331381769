import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  provider: [],
};

const slice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    setProvider(state = initialState, action) {
      state.provider = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setProvider } = slice.actions;
